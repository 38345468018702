import type { RouteRecordRaw } from 'vue-router';

export const webRoutes: RouteRecordRaw = {
  path: '',
  children: [
    {
      path: '',
      name: 'web-main',
      component: () => import('@/modules/web/views/mainView.vue'),
    },
    {
      path: 'suscripcion',
      name: 'web-suscripcion',
      component: () => import('@/modules/web/views/suscripcionView.vue'),
    },
    {
      path: 'terminos-de-usos',
      name: 'web-terminos',
      component: () => import('@/modules/web/views/terminosUsoView.vue'),
    },
    {
      path: 'libro-reclamacion',
      name: 'web-libro-reclamacion',
      component: () => import('@/modules/web/views/libroReclamacionView.vue'),
    },
  ],
};
